import { render, staticRenderFns } from "./UtilityNav.vue?vue&type=template&id=32e3922c&scoped=true&"
import script from "./UtilityNav.vue?vue&type=script&lang=js&"
export * from "./UtilityNav.vue?vue&type=script&lang=js&"
import style0 from "./UtilityNav.vue?vue&type=style&index=0&id=32e3922c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e3922c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32e3922c')) {
      api.createRecord('32e3922c', component.options)
    } else {
      api.reload('32e3922c', component.options)
    }
    module.hot.accept("./UtilityNav.vue?vue&type=template&id=32e3922c&scoped=true&", function () {
      api.rerender('32e3922c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/nav/UtilityNav.vue"
export default component.exports