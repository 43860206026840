import { render, staticRenderFns } from "./ResellersIndex.vue?vue&type=template&id=1d070d81&"
import script from "./ResellersIndex.vue?vue&type=script&lang=js&"
export * from "./ResellersIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d070d81')) {
      api.createRecord('1d070d81', component.options)
    } else {
      api.reload('1d070d81', component.options)
    }
    module.hot.accept("./ResellersIndex.vue?vue&type=template&id=1d070d81&", function () {
      api.rerender('1d070d81', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/resellers/ResellersIndex.vue"
export default component.exports