import { render, staticRenderFns } from "./CustomerStoriesIndex.vue?vue&type=template&id=c2ca0b54&"
import script from "./CustomerStoriesIndex.vue?vue&type=script&lang=js&"
export * from "./CustomerStoriesIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2ca0b54')) {
      api.createRecord('c2ca0b54', component.options)
    } else {
      api.reload('c2ca0b54', component.options)
    }
    module.hot.accept("./CustomerStoriesIndex.vue?vue&type=template&id=c2ca0b54&", function () {
      api.rerender('c2ca0b54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/customer-stories/CustomerStoriesIndex.vue"
export default component.exports