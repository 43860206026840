import { render, staticRenderFns } from "./AccessoriesIndex.vue?vue&type=template&id=d97c653a&"
import script from "./AccessoriesIndex.vue?vue&type=script&lang=js&"
export * from "./AccessoriesIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d97c653a')) {
      api.createRecord('d97c653a', component.options)
    } else {
      api.reload('d97c653a', component.options)
    }
    module.hot.accept("./AccessoriesIndex.vue?vue&type=template&id=d97c653a&", function () {
      api.rerender('d97c653a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/accessories/AccessoriesIndex.vue"
export default component.exports