import { render, staticRenderFns } from "./TrainingCourseInfoRow.vue?vue&type=template&id=fdff6f62&"
import script from "./TrainingCourseInfoRow.vue?vue&type=script&lang=js&"
export * from "./TrainingCourseInfoRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fdff6f62')) {
      api.createRecord('fdff6f62', component.options)
    } else {
      api.reload('fdff6f62', component.options)
    }
    module.hot.accept("./TrainingCourseInfoRow.vue?vue&type=template&id=fdff6f62&", function () {
      api.rerender('fdff6f62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/training-courses/TrainingCourseInfoRow.vue"
export default component.exports