import { render, staticRenderFns } from "./GrantProgramBlockApplyDeadlines.vue?vue&type=template&id=5d4ba9b8&scoped=true&"
import script from "./GrantProgramBlockApplyDeadlines.vue?vue&type=script&lang=js&"
export * from "./GrantProgramBlockApplyDeadlines.vue?vue&type=script&lang=js&"
import style0 from "./GrantProgramBlockApplyDeadlines.vue?vue&type=style&index=0&id=5d4ba9b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4ba9b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d4ba9b8')) {
      api.createRecord('5d4ba9b8', component.options)
    } else {
      api.reload('5d4ba9b8', component.options)
    }
    module.hot.accept("./GrantProgramBlockApplyDeadlines.vue?vue&type=template&id=5d4ba9b8&scoped=true&", function () {
      api.rerender('5d4ba9b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/grant-program/GrantProgramBlockApplyDeadlines.vue"
export default component.exports