import { render, staticRenderFns } from "./SiteSearch.vue?vue&type=template&id=bcc03438&"
import script from "./SiteSearch.vue?vue&type=script&lang=js&"
export * from "./SiteSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master-au/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bcc03438')) {
      api.createRecord('bcc03438', component.options)
    } else {
      api.reload('bcc03438', component.options)
    }
    module.hot.accept("./SiteSearch.vue?vue&type=template&id=bcc03438&", function () {
      api.rerender('bcc03438', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/search/SiteSearch.vue"
export default component.exports